/**
 * IMPORTANT: This file is for dealer-franchisee app initialization.
 * do not use this file for other apps.
 * DO not import anything from the main application. this forms a stand alone
 * initialization for the dealer-franchisee app.
 *
 */
import { checkToClearDB, documentReady, initApiHost, initBranding, initializeApiDetails } from '../../startup';
import { intializeServiceWorker } from '../../softtech-serviceworker-init';

documentReady(async () => {
  await intializeServiceWorker('/softtech-serviceworker.js');
  await checkToClearDB();
  globalThis.applyDebugInformation?.();
  initBranding();
  if (!(await initApiHost())) {
    return;
  }
  await initializeApiDetails('franchisee-app-index', 'Dealer WebModule');
});
